import React from "react";
import {
  Address,
  TransactionUnspentOutput,
} from "@emurgo/cardano-serialization-lib-asmjs";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../components/layout/Footer";
import $, { param } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { DiscordLoginButton } from "react-social-login-buttons";


let Buffer = require("buffer/").Buffer;
var CryptoJS = require("crypto-js");

const API_ENDPOINT =
  "https://django-backend-dot-roborobo-334719.ue.r.appspot.com";
const DEV_API_ENDPOINT =
"http://127.0.0.1:8000/";
const recaptchaRef = React.createRef();

export default class REGISTER extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: "1",
      whichWalletSelected: undefined,
      walletFound: false,
      walletIsEnabled: false,
      walletName: undefined,
      walletIcon: undefined,
      walletAPIVersion: undefined,
      wallets: [],
      rewardAddress: null,

      dev: 0,

      devDiscordAuthURL: "https://discord.com/api/oauth2/authorize?client_id=1066699864626442240&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fregister&response_type=code&scope=identify",
      discordAuthURL: "https://discord.com/api/oauth2/authorize?client_id=1066699864626442240&redirect_uri=https%3A%2F%2Froborobo.gg%2Fregister&response_type=code&scope=identify",
      discordCode: "",
    };

    /**
     * When the wallet is connect it returns the connector which is
     * written to this API variable and all the other operations
     * run using this API object
     */
    this.API = undefined;

    /**
     * Protocol parameters
     * @type {{
     * keyDeposit: string,
     * coinsPerUtxoWord: string,
     * minUtxo: string,
     * poolDeposit: string,
     * maxTxSize: number,
     * priceMem: number,
     * maxValSize: number,
     * linearFee: {minFeeB: string, minFeeA: string}, priceStep: number
     * }}
     */
    this.protocolParams = {
      linearFee: {
        minFeeA: "44",
        minFeeB: "155381",
      },
      minUtxo: "34482",
      poolDeposit: "500000000",
      keyDeposit: "2000000",
      maxValSize: 5000,
      maxTxSize: 16384,
      priceMem: 0.0577,
      priceStep: 0.0000721,
      coinsPerUtxoWord: "34482",
    };

    this.pollWallets = this.pollWallets.bind(this);
  }

  /**
   * Poll the wallets it can read from the browser.
   * Sometimes the html document loads before the browser initialized browser plugins (like Nami or Flint).
   * So we try to poll the wallets 3 times (with 1 second in between each try).
   *
   * Note: CCVault and Eternl are the same wallet, Eternl is a rebrand of CCVault
   * So both of these wallets as the Eternl injects itself twice to maintain
   * backward compatibility
   *
   * @param count The current try count.
   */
  pollWallets = (count = 0) => {
    const wallets = ["eternl", "nami"];
    const walletPreference = localStorage.getItem("roborobo-wallet-preference");
    for (const key in window.cardano) {
      if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
        wallets.push(key);
      }
    }
    if (wallets.length === 0 && count < 3) {
      setTimeout(() => {
        this.pollWallets(count + 1);
      }, 1000);
      return;
    }

    let chosenWallet = null;
    if (walletPreference) {
      chosenWallet = walletPreference;
    } else {
      chosenWallet = wallets[0];
    }

    this.setState(
      {
        wallets,
        whichWalletSelected: chosenWallet,
      },
      () => {
        this.refreshData();
      }
    );
  };

  getRewardAddresses = async () => {
    try {
      const raw = await this.API.getRewardAddresses();
      const rawFirst = raw[0];
      const rewardAddress = Address.from_bytes(
        Buffer.from(rawFirst, "hex")
      ).to_bech32();
      this.setState({ rewardAddress });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Handles the tab selection on the user form
   * @param tabId
   */
  handleTabId = (tabId) => this.setState({ selectedTabId: tabId });

  /**
   * Handles the radio buttons on the form that
   * let the user choose which wallet to work with
   * @param obj
   */
  handleWalletSelect = (obj) => {
    const whichWalletSelected = obj;
    // Setting wallet preference
    localStorage.setItem("roborobo-wallet-preference", obj);
    this.setState({ whichWalletSelected }, () => {
      this.refreshData();
    });
  };

  namiWalletSelect = () => {
    this.handleWalletSelect("nami");
  };

  eternlWalletSelect = () => {
    this.handleWalletSelect("eternl");
  };

  disconnectWallet = () => {
    this.setState({
      selectedTabId: "1",
      whichWalletSelected: undefined,
      walletFound: false,
      walletIsEnabled: false,
      walletName: undefined,
      walletIcon: undefined,
      walletAPIVersion: undefined,
      wallets: [],

      walletNfts: [],
    });
  };

  /**
   * Checks if the wallet is running in the browser
   * Does this for Nami, Eternl and Flint wallets
   * @returns {boolean}
   */

  checkIfWalletFound = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletFound = !!window?.cardano?.[walletKey];
    this.setState({ walletFound });
    return walletFound;
  };

  /**
   * Checks if a connection has been established with
   * the wallet
   * @returns {Promise<boolean>}
   */
  checkIfWalletEnabled = async () => {
    let walletIsEnabled = false;

    try {
      const walletName = this.state.whichWalletSelected;
      walletIsEnabled = await window.cardano[walletName].isEnabled();
    } catch (err) {
      console.log(err);
    }
    this.setState({ walletIsEnabled });

    return walletIsEnabled;
  };

  /**
   * Enables the wallet that was chosen by the user
   * When this executes the user should get a window pop-up
   * from the wallet asking to approve the connection
   * of this app to the wallet
   * @returns {Promise<boolean>}
   */

  enableWallet = async () => {
    const walletKey = this.state.whichWalletSelected;
    try {
      this.API = await window.cardano[walletKey].enable();
    } catch (err) {
      console.log(err);
    }
    return this.checkIfWalletEnabled();
  };

  /**
   * Get the API version used by the wallets
   * writes the value to state
   * @returns {*}
   */
  getAPIVersion = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletAPIVersion = window?.cardano?.[walletKey].apiVersion;
    this.setState({ walletAPIVersion });
    return walletAPIVersion;
  };

  /**
   * Get the name of the wallet (nami, eternl, flint)
   * and store the name in the state
   * @returns {*}
   */

  getWalletName = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletName = window?.cardano?.[walletKey].name;
    this.setState({ walletName });
    return walletName;
  };

  /**
   * Gets the UTXOs from the user's wallet and then
   * stores in an object in the state
   * @returns {Promise<void>}
   */

  getUtxos = async () => {
    let Utxos = [];

    try {
      const rawUtxos = await this.API.getUtxos();

      for (const rawUtxo of rawUtxos) {
        const utxo = TransactionUnspentOutput.from_bytes(
          Buffer.from(rawUtxo, "hex")
        );
        const input = utxo.input();
        const txid = Buffer.from(
          input.transaction_id().to_bytes(),
          "utf8"
        ).toString("hex");
        const txindx = input.index();
        const output = utxo.output();
        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();
        let multiAssetStr = "";

        if (multiasset) {
          const keys = multiasset.keys(); // policy Ids of thee multiasset
          const N = keys.len();
          // console.log(`${N} Multiassets in the UTXO`)

          for (let i = 0; i < N; i++) {
            const policyId = keys.get(i);
            const policyIdHex = Buffer.from(
              policyId.to_bytes(),
              "utf8"
            ).toString("hex");
            // console.log(`policyId: ${policyIdHex}`)
            const assets = multiasset.get(policyId);
            const assetNames = assets.keys();
            const K = assetNames.len();
            // console.log(`${K} Assets in the Multiasset`)

            for (let j = 0; j < K; j++) {
              const assetName = assetNames.get(j);
              const assetNameString = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString();
              const assetNameHex = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString("hex");
              const multiassetAmt = multiasset.get_asset(policyId, assetName);
              multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`;
              // console.log(assetNameString)
              // console.log(`Asset Name: ${assetNameHex}`)
            }
          }
        }

        const obj = {
          txid: txid,
          txindx: txindx,
          amount: amount,
          str: `${txid} #${txindx} = ${amount}`,
          multiAssetStr: multiAssetStr,
          TransactionUnspentOutput: utxo,
        };
        Utxos.push(obj);
      }
      this.setState({ Utxos });
    } catch (err) {
      console.log(err);
    }
  };

  updateState = () => {
    const hostname = window.location.hostname;
    if (hostname === "localhost") {
      this.setState({dev: 1})
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let code = params.code
    if (code) {
      this.setState({ discordCode: code});


      console.log($(this.state.discordCode))
      // Clear the Query string without refreshing
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split('?')[0];
      window.history.pushState({}, '', newUrl);
    }
  };

  /**
   * Refresh all the data from the user's wallet
   * @returns {Promise<void>}
   */
  refreshData = async () => {
    try {
      this.updateState();
      const walletFound = this.checkIfWalletFound();
      if (walletFound) {
        await this.getAPIVersion();
        await this.getWalletName();
        const walletEnabled = await this.enableWallet();
        if (walletEnabled) {
          await this.getRewardAddresses();
          await this.getUtxos();
        } else {
          await this.setState({
            Utxos: null,
            CollatUtxos: null,
            balance: null,
            changeAddress: null,
            rewardAddress: null,
            usedAddress: null,

            txBody: null,
            txBodyCborHex_unsigned: "",
            txBodyCborHex_signed: "",
            submittedTxHash: "",
          });
        }
      } else {
        await this.setState({
          walletIsEnabled: false,

          Utxos: null,
          CollatUtxos: null,
          balance: null,
          changeAddress: null,
          rewardAddress: null,
          usedAddress: null,

          txBody: null,
          txBodyCborHex_unsigned: "",
          txBodyCborHex_signed: "",
          submittedTxHash: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async componentDidMount() {
    this.pollWallets();
    await this.refreshData();
    let outerThis =  this
    // Form on submit
    $("#registerForm").on("submit", function (e) {
      e.preventDefault();

      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      
      let username = $("#username").val();
      let email = $("#email").val();
      let discordCode =  $("#discordCode").val();
      let password1 = $("#password1").val();
      let password2 = $("#password2").val();
      let stake_address = $("#stake_address").val();
      if (recaptchaRef.current.getValue() === "") {
        toast.error("Please complete the Captcha!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return 0;
      }
      if (!validateEmail(email)) {
        toast.error("Email is not valid!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (password1.length < 6) {
        toast.error("Password length must be 6 and above", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (password1 !== password2) {
        toast.error("Password doesn't match", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (stake_address === "") {
        toast.error("Please Connect Wallet!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        var data = {
          username: username,
          email: email,
          discordCode: discordCode,
          password: password1,
          stake_address: stake_address,
          "g-recaptcha-response": recaptchaRef.current.getValue(),
        };

        $.ajax({
          type: "POST",
          url: `${outerThis.state.dev ?  DEV_API_ENDPOINT: API_ENDPOINT}/api/register/`,
          data: JSON.stringify(data),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: function (response) {
            if (response.status === 1) {
              toast.success(`${response.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              username = $("#username").val("");
              email = $("#email").val("");
              password1 = $("#password1").val("");
              password2 = $("#password2").val("");
            }
          },
          error: function (xhr, status, error) {
            let json_obj = JSON.parse(xhr.responseText);
            if (json_obj.status === 0) {
              toast.error(`${json_obj.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          },
        });
        recaptchaRef.current.reset();
      }
    });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <section className="center-content ">
          <div className="">
            <video
              src={
                "https://cdn.glitch.global/ed68b475-3123-4866-a3da-f678c2af24da/WEBSITE_CINEMATIC_LOW.m4v?v=1669204020061"
              }
              playsInline
              autoPlay
              loop
              muted
            />
            {this.state.discordCode ? (
              <div className="d-flex justify-content-center">
                <form className="register-container" id="registerForm">
                  <h2>Register</h2>
                  <input type="text" id="username" required />
                  <label for="text">
                    <span>Username</span>
                  </label>
                  <input type="text" id="email" required />
                  <label for="text">
                    <span>Email</span>
                  </label>
                  <input type="password" name="" id="password1" required />
                  <label for="password">
                    <span>Password</span>
                  </label>
                  <input type="password" name="" id="password2" required />
                  <label for="password">
                    <span>Retype Password</span>
                  </label>
                  <input type="hidden" id="discordCode" value={this.state.discordCode || ''} />
                  {/* Connect wallet */}
                  <div className="d-flex justify-content-center">
                    <button
                      className="whitepaper-button mx-2"
                      onClick={this.namiWalletSelect}
                      type="button"
                    >
                      <span>
                        {this.state.walletIsEnabled &&
                        this.state.whichWalletSelected === "nami"
                          ? `Nami Connected`
                          : "Connect Nami"}
                      </span>
                    </button>
                    <button
                      className="whitepaper-button mx-2"
                      onClick={this.eternlWalletSelect}
                      type="button"
                    >
                      <span>
                        {this.state.walletIsEnabled &&
                        this.state.whichWalletSelected === "eternl"
                          ? `Eternl Connected`
                          : "Connect Eternl"}
                      </span>
                    </button>
                  </div>
                  {this.state.walletIsEnabled ? (
                    <BrowserView className="switch-wallet-button">
                      <p>
                        {this.state.rewardAddress
                          ? this.state.rewardAddress.replace(
                              this.state.rewardAddress.substring(
                                6,
                                this.state.rewardAddress.length - 3
                              ),
                              "*******"
                            )
                          : "Loading..."}
                      </p>
                    </BrowserView>
                  ) : (
                    <BrowserView className="switch-wallet-button">
                      <p>
                        {this.state.rewardAddress
                          ? this.state.rewardAddress.replace(
                              this.state.rewardAddress.substring(
                                6,
                                this.state.rewardAddress.length - 3
                              ),
                              "*******"
                            )
                          : "No wallet"}
                      </p>
                    </BrowserView>
                  )}
                  <input
                    id="stake_address"
                    type="hidden"
                    value={this.state.rewardAddress}
                  />
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeX6vgjAAAAAGA1CPcY9qOcCusHP0H-Maru4G96"
                      theme="dark"
                      required
                    />
                  </div>
                  <button type="submit">Register</button>
                </form>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="register-container">
                  <DiscordLoginButton onClick={() => this.state.dev ? window.location.href = this.state.devDiscordAuthURL: window.location.href = this.state.discordAuthURL} />
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}
