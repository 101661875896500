import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import REGISTER from './views/register';
import PrivacyPolicy from './views/PrivacyPolicy';
import leaderBoard from './views/LeaderBoard';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZ1e79YAv0rEu8chAFmg8kU7SM5dCTK4g",
  authDomain: "roborobo-be609.firebaseapp.com",
  projectId: "roborobo-be609",
  storageBucket: "roborobo-be609.appspot.com",
  messagingSenderId: "374771432482",
  appId: "1:374771432482:web:992ca5c04c9c139045de7b",
  measurementId: "G-0VQDJXB6F3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className='App'>
      
      <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <Route exact path="/" component={Home} layout={LayoutDefault} />
          <Route exact path="/register/" component={REGISTER} layout={LayoutDefault} />
          <Route exact path="/PrivacyPolicy/" component={PrivacyPolicy} layout={LayoutDefault} />
          <Route exact path="/leaderBoard/" component={leaderBoard} layout={LayoutDefault} />
          {/* <Route exact path="/robo-ar/" component={AR} layout={LayoutDefault} /> */}
        </Switch>
      )} />
    </div>
  );
}

export default App;