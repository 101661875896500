import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mt-5">
        <h1 className="my-3">Privacy Policy </h1>
        <p>
          This Privacy Policy describes how we collect, use, and disclose
          personal information when you use our game (ROBOROBO: Zignar
          Conflict). We respect your privacy and are committed to protecting
          your personal information.
        </p>

        <hr />

        <h3 className="my-3">1 - Information We Collect</h3>
        <p>
          When you create an account and play the game, we may collect the
          following types of personal information:
        </p>
        <ul>
          <li>
            Information about your device: We may collect information about the
            type of device you use, its unique device identifier, the operating
            system you use, the version of the Game you use, and the type of
            browser you use.
          </li>
          <li>
            Usage data: We may collect information about how you use the Game,
            including the time and duration of your use, the features you use,
            and the interactions you have with the Game.
          </li>
          <li>
            Technical data: We may collect technical data about your device and
            internet connection, such as your IP address, browser type, and
            version.
          </li>
          <li>
            Location data: We may collect information about your location, such
            as your country, city, or postal code, if you provide it or if your
            device settings permit us to collect it.
          </li>
          <li>
            We collect this information using various technologies, including
            cookies and other services like Epic Online Services and Microsoft
            Playfab.
          </li>
        </ul>

        <h3 className="my-3">2 - How We Use Your Information</h3>
        <p>We use your personal information for the following purposes:</p>
        <ul>
          <li>
            To provide and improve the game: We use your personal information to
            operate and maintain the game, to develop new features and
            functionalities, and to improve the game's performance.
          </li>
          <li>
            To personalize your experience: We use your personal information to
            tailor your experience of the game to your preferences and
            interests.
          </li>
          <li>
            To communicate with you: We may use your personal information to
            respond to your inquiries and requests, to send you promotional
            messages and marketing materials, and to provide you with
            information about the game and our project.
          </li>
          <li>
            To comply with legal obligations: We may use your personal
            information to comply with applicable laws, regulations, and legal
            proceedings.
          </li>
          <li>
            To protect our rights: We may use your personal information to
            protect our rights, property, or safety, and the rights, property,
            or safety of others.
          </li>
        </ul>
        <h3 className="my-3">3 - Sharing Your Information</h3>
        <p>
          We may share your personal information with third-party service
          providers who provide services to us, such as analytics services,
          hosting services, and customer support services. We will never share
          your personal information with our affiliates and partners for
          promotional purposes.
        </p>

        <p>
          We may disclose your personal information if we believe that it is
          necessary to:
        </p>

        <ul>
          <li>
            Comply with applicable laws, regulations, or legal proceedings;
          </li>
          <li>Enforce our policies and terms of use;</li>
          <li>
            Protect our rights, property, or safety, and the rights, property,
            or safety of others.
          </li>
        </ul>

        <h3 className="my-3">4 - Data Retention</h3>
        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes for which we collected it, unless a longer
          retention period is required by law. We may also retain your personal
          information for the purposes of establishing, exercising, or defending
          legal claims.
        </p>

        <h3 className="my-3">5 - Your Rights</h3>
        <p>
          You have certain rights with respect to your personal information,
          including the right to:
        </p>

        <p className="text-white mb-1">Access your personal information:</p>
        <ul>
          <li>
            You have the right to request access to your personal information
            that we hold.
          </li>
        </ul>

        <p className="text-white mb-1">Correct your personal information:</p>
        <ul>
          <li>
            You have the right to request that we correct any inaccuracies or
            errors in your personal information.
          </li>
        </ul>

        <p className="text-white mb-1">Delete your personal information:</p>
        <ul>
          <li>
            You have the right to request that we delete your personal
            information under certain circumstances, such as when it is no
            longer necessary for the purposes for which we collected it.
          </li>
        </ul>

        <p className="text-white mb-1">
          Object to the processing of your personal information:
        </p>
        <ul>
          <li>
            You have the right to object to the processing of your personal
            information under certain circumstances, such as when we are relying
            on our legitimate interests as a legal basis for processing.
          </li>
        </ul>
        <p className="text-white">
          To exercise any of these rights, please contact us using our email
          roborobo.cnft@gmail.com or in our discord server.
        </p>

        <h3 className="my-3">6 - Security</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is completely
          secure, so we cannot guarantee the absolute security of your personal
          information.
        </p>

        <h3 className="my-3">7 - Children's Privacy</h3>
        <p>
          The game is not intended for use by children under the age of 13. If
          you are under the age of 13, please do not download and play the game
          or provide any personal information to us. If we become aware that we
          have collected personal information from a child under the age of 13,
          we will take steps to delete the information as soon as possible.
        </p>

        <h3 className="my-3">8 - Changes to this Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or applicable laws. We will post the updated Privacy
          Policy in our website and discord, and indicate the date of the most
          recent update. Your continued play of the game after the posting of
          the updated Privacy Policy constitutes your acceptance of the updated
          Privacy Policy.
        </p>

        <h3 className="my-3">9 - Contact Us</h3>
        <p className="mb-5">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <strong className="text-white">roborobo.cnft@gmail.com</strong> or in
          our discord server. We will do our best to address your concerns in a
          timely and appropriate manner.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
