import React from "react";
import {
  Address,
  TransactionUnspentOutput,
} from "@emurgo/cardano-serialization-lib-asmjs";
import { ceil } from "lodash";
import { BrowserView, MobileView } from "react-device-detect";
import $ from "jquery";

let Buffer = require("buffer/").Buffer;
var CryptoJS = require("crypto-js");

export default class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: "1",
      whichWalletSelected: undefined,
      walletFound: false,
      walletIsEnabled: false,
      walletName: undefined,
      walletIcon: undefined,
      walletAPIVersion: undefined,
      wallets: [],
      rewardAddress: null,
    };

    /**
     * When the wallet is connect it returns the connector which is
     * written to this API variable and all the other operations
     * run using this API object
     */
    this.API = undefined;

    /**
     * Protocol parameters
     * @type {{
     * keyDeposit: string,
     * coinsPerUtxoWord: string,
     * minUtxo: string,
     * poolDeposit: string,
     * maxTxSize: number,
     * priceMem: number,
     * maxValSize: number,
     * linearFee: {minFeeB: string, minFeeA: string}, priceStep: number
     * }}
     */
    this.protocolParams = {
      linearFee: {
        minFeeA: "44",
        minFeeB: "155381",
      },
      minUtxo: "34482",
      poolDeposit: "500000000",
      keyDeposit: "2000000",
      maxValSize: 5000,
      maxTxSize: 16384,
      priceMem: 0.0577,
      priceStep: 0.0000721,
      coinsPerUtxoWord: "34482",
    };

    this.pollWallets = this.pollWallets.bind(this);
  }

  /**
   * Poll the wallets it can read from the browser.
   * Sometimes the html document loads before the browser initialized browser plugins (like Nami or Flint).
   * So we try to poll the wallets 3 times (with 1 second in between each try).
   *
   * Note: CCVault and Eternl are the same wallet, Eternl is a rebrand of CCVault
   * So both of these wallets as the Eternl injects itself twice to maintain
   * backward compatibility
   *
   * @param count The current try count.
   */
  pollWallets = (count = 0) => {
    const wallets = ["eternl", "nami"];
    const walletPreference = localStorage.getItem('roborobo-wallet-preference');
    for (const key in window.cardano) {
      if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
        wallets.push(key);
      }
    }
    if (wallets.length === 0 && count < 3) {
      setTimeout(() => {
        this.pollWallets(count + 1);
      }, 1000);
      return;
    }

    let chosenWallet = null;
    if (walletPreference) {
      chosenWallet = walletPreference
    } else {
      chosenWallet = wallets[0];
    }

    this.setState(
      {
        wallets,
        whichWalletSelected: chosenWallet,
      },
      () => {
        this.refreshData();
      }
    );
  };

  getRewardAddresses = async () => {
    try {
      const raw = await this.API.getRewardAddresses();
      const rawFirst = raw[0];
      const rewardAddress = Address.from_bytes(
        Buffer.from(rawFirst, "hex")
      ).to_bech32();
      console.log(rewardAddress);
      this.setState({ rewardAddress });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Handles the tab selection on the user form
   * @param tabId
   */
  handleTabId = (tabId) => this.setState({ selectedTabId: tabId });

  /**
   * Handles the radio buttons on the form that
   * let the user choose which wallet to work with
   * @param obj
   */
  handleWalletSelect = (obj) => {
    const whichWalletSelected = obj;
    // Setting wallet preference
    localStorage.setItem('roborobo-wallet-preference', obj);
    this.setState({ whichWalletSelected }, () => {
      this.refreshData();
    });
  };

  namiWalletSelect = () => {
    this.handleWalletSelect("nami");
  };

  eternlWalletSelect = () => {
    this.handleWalletSelect("eternl");
  };

  disconnectWallet = () => {
    this.setState({
      selectedTabId: "1",
      whichWalletSelected: undefined,
      walletFound: false,
      walletIsEnabled: false,
      walletName: undefined,
      walletIcon: undefined,
      walletAPIVersion: undefined,
      wallets: [],

      walletNfts: [],
    });
  };

  /**
   * Checks if the wallet is running in the browser
   * Does this for Nami, Eternl and Flint wallets
   * @returns {boolean}
   */

  checkIfWalletFound = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletFound = !!window?.cardano?.[walletKey];
    this.setState({ walletFound });
    return walletFound;
  };

  /**
   * Checks if a connection has been established with
   * the wallet
   * @returns {Promise<boolean>}
   */
  checkIfWalletEnabled = async () => {
    let walletIsEnabled = false;

    try {
      const walletName = this.state.whichWalletSelected;
      walletIsEnabled = await window.cardano[walletName].isEnabled();
    } catch (err) {
      console.log(err);
    }
    this.setState({ walletIsEnabled });

    return walletIsEnabled;
  };

  /**
   * Enables the wallet that was chosen by the user
   * When this executes the user should get a window pop-up
   * from the wallet asking to approve the connection
   * of this app to the wallet
   * @returns {Promise<boolean>}
   */

  enableWallet = async () => {
    const walletKey = this.state.whichWalletSelected;
    try {
      this.API = await window.cardano[walletKey].enable();
    } catch (err) {
      console.log(err);
    }
    return this.checkIfWalletEnabled();
  };

  /**
   * Get the API version used by the wallets
   * writes the value to state
   * @returns {*}
   */
  getAPIVersion = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletAPIVersion = window?.cardano?.[walletKey].apiVersion;
    this.setState({ walletAPIVersion });
    return walletAPIVersion;
  };

  /**
   * Get the name of the wallet (nami, eternl, flint)
   * and store the name in the state
   * @returns {*}
   */

  getWalletName = () => {
    const walletKey = this.state.whichWalletSelected;
    const walletName = window?.cardano?.[walletKey].name;
    this.setState({ walletName });
    return walletName;
  };

  /**
   * Gets the UTXOs from the user's wallet and then
   * stores in an object in the state
   * @returns {Promise<void>}
   */

  getUtxos = async () => {
    let Utxos = [];

    try {
      const rawUtxos = await this.API.getUtxos();

      for (const rawUtxo of rawUtxos) {
        const utxo = TransactionUnspentOutput.from_bytes(
          Buffer.from(rawUtxo, "hex")
        );
        const input = utxo.input();
        const txid = Buffer.from(
          input.transaction_id().to_bytes(),
          "utf8"
        ).toString("hex");
        const txindx = input.index();
        const output = utxo.output();
        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();
        let multiAssetStr = "";

        if (multiasset) {
          const keys = multiasset.keys(); // policy Ids of thee multiasset
          const N = keys.len();
          // console.log(`${N} Multiassets in the UTXO`)

          for (let i = 0; i < N; i++) {
            const policyId = keys.get(i);
            const policyIdHex = Buffer.from(
              policyId.to_bytes(),
              "utf8"
            ).toString("hex");
            // console.log(`policyId: ${policyIdHex}`)
            const assets = multiasset.get(policyId);
            const assetNames = assets.keys();
            const K = assetNames.len();
            // console.log(`${K} Assets in the Multiasset`)

            for (let j = 0; j < K; j++) {
              const assetName = assetNames.get(j);
              const assetNameString = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString();
              const assetNameHex = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString("hex");
              const multiassetAmt = multiasset.get_asset(policyId, assetName);
              multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`;
              // console.log(assetNameString)
              // console.log(`Asset Name: ${assetNameHex}`)
            }
          }
        }

        const obj = {
          txid: txid,
          txindx: txindx,
          amount: amount,
          str: `${txid} #${txindx} = ${amount}`,
          multiAssetStr: multiAssetStr,
          TransactionUnspentOutput: utxo,
        };
        Utxos.push(obj);
      }
      this.setState({ Utxos });
    } catch (err) {
      console.log(err);
    }
  };

  detectVerifiedNft = async () => {
    try {
      let tempNftArray = [];
      let availableNftString = "";
      let roboroboPolicyId =
        "e4a1287629574fe5dd8025c3c6569680e0e6e883a2d8e9e48a516d2a";
      this.state.Utxos?.map(function (x) {
        if (x.multiAssetStr.includes(`${roboroboPolicyId}`)) {
          let tempNftData = {
            name: "Roborobo NFT",
            image:
              "https://cdn.glitch.global/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/NOZOMI%20Twitter%20logo.png?v=1668505993590",
            glb: "https://cdn.glitch.me/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/ROBO_01.glb?v=1668141847233",
            usdz: "https://cdn.glitch.me/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/ROBO_01.usdz?v=1668151389905",
          };

          if (!tempNftArray.some((e) => e.name === "Roborobo NFT")) {
            tempNftArray.push(tempNftData);
          }

          availableNftString += "Roborobo NFT";
        }
        return tempNftArray;
      });
      // Encrypt
      var ciphertext = CryptoJS.AES.encrypt(
        availableNftString,
        `${Intl.DateTimeFormat().resolvedOptions().timeZone}${
          ceil(Date.now() / 100000) * 100000
        }`
      ).toString();

      this.setState({ encryptedCode: ciphertext });

      this.setState({ walletNfts: tempNftArray });
    } catch (err) {
      console.log(err);
    }
  };

  updateChosenNft = (id) => {
    try {
      let item = document.getElementById(id);
      this.setState({ selectedNftName: item.getAttribute("data-name") });
      this.setState({ selectedNftGlb: item.getAttribute("data-glb") });
      this.setState({ selectedNftUsdz: item.getAttribute("data-usdz") });
    } catch (err) {
      console.log(err);
    }
  };

  getQueryString = () => {
    function getUrlVars() {
      var vars = [],
        hash;
      var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    }

    let tempNftArray = [];
    let query_value = getUrlVars()["value"];

    if (query_value) {
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(
        query_value,
        `${Intl.DateTimeFormat().resolvedOptions().timeZone}${(
          ceil(Date.now() / 100000) * 100000
        ).toString()}`
      );
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData.includes("Roborobo NFT")) {
        tempNftArray.push({
          name: "Roborobo NFT",
          image:
            "https://cdn.glitch.global/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/NOZOMI%20Twitter%20logo.png?v=1668505993590",
          glb: "https://cdn.glitch.me/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/ROBO_01.glb?v=1668141847233",
          usdz: "https://cdn.glitch.me/69e0bc5c-b78e-4add-ba8f-ab4e9b53511a/ROBO_01.usdz?v=1668151389905",
        });
      }
    }
    this.setState({ walletNfts: tempNftArray });
  };

  toggleQrBox = () => {
    this.setState({ showQrBox: !this.state.showQrBox });
  };

  /**
   * Refresh all the data from the user's wallet
   * @returns {Promise<void>}
   */
  refreshData = async () => {
    try {
      const walletFound = this.checkIfWalletFound();
      this.getQueryString();
      if (walletFound) {
        await this.getAPIVersion();
        await this.getWalletName();
        const walletEnabled = await this.enableWallet();
        if (walletEnabled) {
          await this.getUtxos();
          await this.detectVerifiedNft();
          await this.getRewardAddresses();
        } else {
          await this.setState({
            Utxos: null,
            CollatUtxos: null,
            balance: null,
            changeAddress: null,
            rewardAddress: null,
            usedAddress: null,

            txBody: null,
            txBodyCborHex_unsigned: "",
            txBodyCborHex_signed: "",
            submittedTxHash: "",
          });
        }
      } else {
        await this.setState({
          walletIsEnabled: false,

          Utxos: null,
          CollatUtxos: null,
          balance: null,
          changeAddress: null,
          rewardAddress: null,
          usedAddress: null,

          txBody: null,
          txBodyCborHex_unsigned: "",
          txBodyCborHex_signed: "",
          submittedTxHash: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async componentDidMount() {
    this.pollWallets();
    //appends an "active" class to .popup and .popup-content when the "Open" button is clicked
    $(".open").on("click", function () {
      $(".popup-overlay, .popup-content").addClass("active");
    });

    //removes the "active" class to .popup and .popup-content when the "Close" button is clicked
    $(".close, .popup-overlay").on("click", function () {
      $(".popup-overlay, .popup-content").removeClass("active");
    });
    await this.refreshData();
  }

  render() {
    return (
      <>
        <section className="hero section center-content illustration-section-01">
          <div className="hero-container">
            <img src="https://cdn.glitch.global/ed68b475-3123-4866-a3da-f678c2af24da/roborobo_logo.png?v=1679371744448" />
            <div className="robo-hero-middle">
                <a
                  href="https://shorturl.at/gHKX0"
                  className="robo-button-hero-middle mx-1"
                >
                  <span>PLAY ALPHA VERSION</span>
                </a>
                <a
                  href="https://roborobo.gg/Register"
                  className="robo-button-hero-middle mx-1"
                >
                  <span>CREATE ACCOUNT</span>
                </a>
            </div>
            <video
              src={
                "https://cdn.glitch.global/ed68b475-3123-4866-a3da-f678c2af24da/WEBSITE_CINEMATIC_LOW.m4v?v=1669204020061"
              }
              playsInline
              autoPlay
              loop
              muted
            />
            {this.state.walletIsEnabled ? (
              <BrowserView className="switch-wallet-button">
                <button className="robo-button-hero open">
                  <span>
                    {this.state.rewardAddress
                      ? this.state.rewardAddress.replace(
                          this.state.rewardAddress.substring(
                            6,
                            this.state.rewardAddress.length - 3
                          ),
                          "*******"
                        )
                      : "Loading..."}
                  </span>
                </button>
              </BrowserView>
            ) : (
              <BrowserView className="switch-wallet-button">
                <button className="robo-button-hero open">
                  <span>
                    {this.state.rewardAddress
                      ? this.state.rewardAddress.replace(
                          this.state.rewardAddress.substring(
                            6,
                            this.state.rewardAddress.length - 3
                          ),
                          "*******"
                        )
                      : "No wallet"}
                  </span>
                </button>
              </BrowserView>
            )}
            {this.state.walletIsEnabled ? (
              <BrowserView className="hero-buttons d-flex">
                <a
                  href="https://roborobo.gg/PrivacyPolicy"
                  className="robo-button-hero mx-1"
                >
                  <span>PRIVACY POLICY</span>
                </a>
                <a
                  href="https://labs.mutant-nft.com/projects/roborobo"
                  className="robo-button-hero mx-1"
                >
                  <span>STAKING</span>
                </a>
                <a
                  href="https://roborobo.gg/leaderboard"
                  className="robo-button-hero mx-1"
                >
                  <span>LEADERBOARD</span>
                </a>
                <a href="" className="robo-button-hero mx-1">
                  <span>ROBO AR (TBA)</span>
                </a>
                <a
                  href={window.location.pathname}
                  className="robo-button-hero mx-1"
                >
                  <span>STORE</span>
                </a>
                <a
                  href="https://whitepaper.roborobo.gg/"
                  className="robo-button-hero mx-1"
                >
                  <span>ROBOPAPER</span>
                </a>
              </BrowserView>
            ) : (
              <BrowserView className="hero-buttons d-flex">
                <button className="robo-button-hero open">
                  <span>CONNECT</span>
                </button>
                <a
                  href="https://whitepaper.roborobo.gg/"
                  className="robo-button-hero mx-1"
                >
                  <span>ROBOPAPER</span>
                </a>
              </BrowserView>
            )}
            <MobileView className="hero-buttons d-flex">
              <a
                href="https://whitepaper.roborobo.gg/"
                className="robo-button-hero mx-1"
              >
                <span>ROBOPAPER</span>
              </a>
            </MobileView>
            <div className="popup-overlay">
              <div className="popup-content">
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="close mx-3 my-2">&#10005;</div>{" "}
                </div>
                <h2>Select Wallet</h2>
                <div>
                  <button
                    className="modal-connect-button open"
                    onClick={this.namiWalletSelect}
                  >
                    <span>Nami</span>
                  </button>
                </div>
                <div>
                  <button
                    className="modal-connect-button open"
                    onClick={this.eternlWalletSelect}
                  >
                    <span>Eternl</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
