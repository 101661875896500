import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Header from '../components/layout/Header';

const Home = () => {

  return (
    <>
      <Header navPosition="right" className="reveal-from-bottom" />
      <Hero className="illustration-section-01" />
    </>
  );
}

export default Home;